/****
 *  Usage
 *  var lgConfig= {
 *      appId: "Account ID",
 *      apiKey: "Provider Key",
 *      <optional> form: "Form Key",
 *
 *  }
 * var lgCustom= {
 *     onSuccess: function(){
 *          // Action
 *     }
 * }
 * @param Config
 * @returns {{formKey: (*|string)}}
 */

import stringToHTML from "./utils/stringToHtml";
import getPopulateQuery from "./utils/populateQuery";
import valueExist from "./utils/selectValueExist";
import createSelectPlaceholder from "./utils/createSelectPlaceholder";

const API_URL_PROD = "eu1-api.leadgrease.com";
const API_URL_DEV = "localhost:8003";


 /* Control submit lead */
let SubmitLead=true;

function buildFormJson(Config) {
    let formOrigin = document.getElementById(Config.formContainer);
    var arr = formOrigin.elements, i = 0, l = arr.length;
    var fillFields = Config.fillFields, ii = 0, ll = fillFields.length;
    let formHasJson = {
        formKey: Config.formKey
    };
    for (var formFields in formOrigin.elements) {
        for (var field in Config.fillFields) {ò
            if (formOrigin.elements[formFields].name === field)
                formHasJson[Config.fillFields[field]] = formOrigin.elements[formFields].value
        }
    }
    for (var field in Config.customFields) {
        formHasJson[field] = Config.customFields[field];
    }

    return formHasJson;
}

function getArrayFromForm(formOrigin) {
    let formHasJson = {};
    //let formOrigin = vodocument.getElementById(Form);
    let arrFields = formOrigin.elements, i = 0, l = arrFields.length;
    for (var formFields in formOrigin.elements) {
        if (formOrigin.elements[formFields].name !== ""){
            let element = formOrigin.elements[formFields];
            if(element.type == "checkbox")
                formHasJson[element.name] = element.checked;
            else
                formHasJson[element.name] = element.value;
        }



    }
    return formHasJson;
}
function createOverlay(){
    let overlay = document.getElementById('loader');

    if(overlay)
        overlay.style.display="block";
    else{

            let overlay = document.createElement('div');
            overlay.id = "loader";
            overlay.classList.add('overlay', 'position-fixed', 'justify-content-center', "d-flex", 'align-items-center', 'fixed-top', 'w-100', 'h-100');
            overlay.style.backgroundColor = "#00000066";
            overlay.innerHTML = `
     <div class="spinner-border text-warning" role="status">
     <span class="sr-only">Loading...</span>
     </div>`;

            document.querySelector('body').prepend(overlay);
        }
}


function removeOverlay(){

    let overlay = document.getElementById('loader');

    if(overlay)
        overlay.style.setProperty("display", "none", "important");

}
function composeOrigin(FormData){
    let query_params = new URLSearchParams(window.location.search);
    if(query_params.get('origin')){
        FormData.origin = query_params.get('origin');
    }
    
    return FormData;
}
function dataProxy(FormData) {

    FormData = composeBirthDay(FormData); // To dissmiss
    FormData = composeBirthDayV2(FormData);
    FormData = composeSponsor(FormData);
    FormData = composeOrigin(FormData);
    
    return FormData;
}

function getAuthenticationHeaders(){
    let auth = {};

    if(lgConfig.apiKey)
        auth = {
            ...auth,
            "x-api-key": lgConfig.apiKey,
        }

    if(lgConfig.appId)
        auth = {
            ...auth,
            "x-app-id": lgConfig.appId,
        }

    if(lgConfig.formKey)
        auth = {
            ...auth,
            "x-form-token": lgConfig.formKey,
        }
    if(lgConfig.formUid)
        auth = {
            ...auth,
            "x-form-uid": lgConfig.formUid,
        }

    return auth;
}

function sendData(FormData, apiKey, appId, callbackSuccess, callbackError) {

    let dataForm = dataProxy(FormData);

    let auth = getAuthenticationHeaders();

    var miInit = {
        method: 'POST',
        headers: {
            ...auth,
            "content-type": "application/json",
        },
        mode: 'cors',
        credentials: 'include',
        cache: 'reload',
        body: JSON.stringify(dataForm)
    };


    fetch( lgConfig.isDev === true ? `http://${API_URL_DEV}/capture/pingpost` : `https://${API_URL_PROD}/capture/pingpost`, miInit)
        .then(function (response) {
            
            let button = document.querySelectorAll("button[type=submit]");
            button[0].classList.add("disabled")
            button[0].removeEventListener("click", function () {

            });
            response.json().then(function (dataMain) {



                
                if ( (dataMain.pingPostResponse && dataMain.pingPostResponse.status === "ok" && dataMain.status === "ok") 
                        || (dataMain.status === "ok" && !dataMain.pingPostResponse) ){
                    if(typeof lgPixels != "undefined" && lgPixels.pixels){
                        for (let pixel in lgPixels.pixels) {
                            let leadId;
                            if (typeof lgCustom != "undefined" && lgCustom.leadIdFromCampaignIntegration)
                                leadId = lgCustom.leadIdFromCampaignIntegration(dataMain);
                            else
                                leadId = dataMain.leadId;
                            let url = replaceWith(lgPixels.pixels[pixel], "[leadId]", leadId);
                            $("<img></img>").attr("src", url).css("display", "none").appendTo($("body"));
                        }
                    }
                    
                    
                }
                
                if (typeof (lgCustom) !== "undefined" && typeof (lgCustom.onResponse) !== "undefined") window.setTimeout(function () { lgCustom.onResponse(response); },600);
                if (typeof (lgCustom) !== "undefined" && typeof (lgCustom.onCustomResponse) !== "undefined") window.setTimeout(function () {lgCustom.onCustomResponse(dataMain);  },600);


               /** Solo cuando tenemos Steps Habilitados **/

               if(typeof (stepsConfig) !== "undefined"){
                   nextStep(parseInt(stepsConfig.currentStep + 1), dataMain.leadId);
               }

            });
            
            

        }).catch(function (error) {
            console.error(error);
            if (typeof (callbackError) !== "undefined") callbackError(error);
        });
}
function replaceWith(url, shortcode, withItem) {


    return url.replace(shortcode, withItem);

}




function getParameterByName(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(location.search);
    return results == null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

/**
 * BirtDayComposer
 */
function composeBirthDay(FormData) {
    if (document.querySelector("[data-type='birth']")) {
        var birthDayYear = document.querySelector("[data-type='birthday-year']");
        var birthDayMonth = document.querySelector("[data-type='birthday-month']");
        var birthDaySelect = document.querySelector("[data-type='birthday-day']");

        delete FormData[birthDaySelect.name];
        delete FormData[birthDayMonth.name];
        delete FormData[birthDayYear.name];
        FormData.birth_date = birthDayYear.value + "-" + (parseInt(birthDayMonth.value) + 1) + "-" + birthDaySelect.value;
    }
    return FormData;
}

function composeBirthDayV2(FormData) {
    if (document.querySelector("[data-slg-type='C-BIRTHDAY']")) {
        let birthDayYear = document.querySelector("[data-slg-type='C-SELECT-YEAR']");
        let birthDayMonth = document.querySelector("[data-slg-type='C-SELECT-MONTH']");
        let birthDaySelect = document.querySelector("[data-slg-type='C-SELECT-DAY']");

        delete FormData[birthDaySelect.name];
        delete FormData[birthDayMonth.name];
        delete FormData[birthDayYear.name];
        FormData.birth_date = birthDayYear.value + "-" + (parseInt(birthDayMonth.value) + 1) + "-" + birthDaySelect.value;
    }
    return FormData;
}

/**
 * 
 * composeSponsor
 */

function composeSponsor(FormData) {
    if (document.querySelector("#sponsor")){
        var sponsors = document.querySelectorAll(".sponsor-list input");
        sponsors = Array.from(sponsors).filter((item) => {
            return item.checked == true;
        }); 


        sponsors = Array.from(sponsors).map((item) => {
            return item.value;
        });
        
        FormData.sponsor = sponsors;
    }
    return FormData;
}



/**
 * Coregistros
 */

function initQuestionsOptions(){
    let questions = document.querySelectorAll('[data-slg-type="C-QUESTION-OPTIONS"]');
    for (let i = 0; i < questions.length; i++) {
        const question = questions[i];
        initCoregistro(question);
    }
    
}

function serializeForm( form ){
    let inputs = form.querySelectorAll('input');
    let select = form.querySelectorAll('select');
    return  [
        ...inputs,
        ...select
    ];
}


function initCoregistro(el){
    // case select
    let select_options = el.querySelector('[data-slg-type="C-SELECT-OPTIONS"]');
    let container_coregistro = el.querySelector('[data-slg-type="C-CONTAINER-COREGISTRO"]');
    if(select_options){
        checkSelectedOption(select_options,container_coregistro);
        select_options.addEventListener('change',(e)=>{
            checkSelectedOption(select_options,container_coregistro);
        });
    }
    // case radio
    let radio_options = el.querySelector('[data-slg-type="C-RADIO-GROUP"]');
    if(radio_options && container_coregistro){
        let options = radio_options.querySelectorAll('input[type="radio"]');
        for (let i = 0; i < options.length; i++) {
            const option = options[i];
            let coregistros = option.getAttribute('data-slg-coregistros');
            coregistros = JSON.parse(coregistros);

            let coregistros_inputs = document.querySelectorAll('input');


            if(coregistros.length > 0){
                option.addEventListener('click',()=>{
                    container_coregistro.style.display = "block";
                    Array.from(coregistros_inputs).map((input)=>{
                        input.setAttribute('required','');
                    });
                });
            }else{
                option.addEventListener('click',()=>{
                    container_coregistro.style.display = "none";
                    Array.from(coregistros_inputs).map((input)=>{
                        input.removeAttribute('required','');
                    });
                });
            }
        }
    }
}

function checkSelectedOption(select_options,container_coregistro){
    let option = select_options.querySelector(`option[value="${select_options.value}"]`);
    let coregistros = option.getAttribute('data-slg-coregistros');
    coregistros = JSON.parse(coregistros);
    if(coregistros.length > 0 && container_coregistro){
        container_coregistro.style.display = "block";
    }else if(container_coregistro){
        container_coregistro.style.display = "none";
    }
}

/**
 * Get sponsors
 */

function initModalSponsors(){
    let modal = document.querySelector('#sponsor');

    if(modal) {
        let btns_close = modal.querySelectorAll('.btn-close');
        let modal_content = modal.querySelector('.modal-content');
        if (btns_close.length > 0 && modal_content) {
            /* Event button closed modal */
            Array.from(btns_close).forEach((btn_close)=>{
                btn_close.addEventListener('click', (e) => {
                    modal.classList.remove('show');
                    modal.style.display = "";
                    document.querySelector('.modal-backdrop').remove();
                });
            })
            /* Event click outside modal */
            modal.addEventListener('click', function (e) {
                if (modal_content.contains(e.target)) {
                    // Clicked in box
                } else {
                    // Clicked outside the box
                    modal.classList.remove('show');
                    modal.style.display = "";
                    document.querySelector('.modal-backdrop').remove();
                }
            });
        }
    }
    let trigger = document.querySelector('[data-target="#sponsor"]')
    trigger.addEventListener('click',()=>{
        modal.classList.add('show');
        modal.style.display = "block";

        let modal_backdrop = document.createElement('div');
        modal_backdrop.classList.add('modal-backdrop', 'fade', 'show');

        document.body.appendChild(modal_backdrop);
    });
}

function addStylesSwitchSponsors(){
    let styles = document.createElement("style");
    styles.innerText = `
        .modal .sponsors-title{
            font-weight: bold;
            font-size: 1.2em;
        }
        
        .modal .sponsor-list .spon{
            display:flex;
        }
        .modal .sponsor-list .spon-title{
            color:black;
            font-weight: bold;
            font-size: 1.2em;
            margin:0.7em 0;
        }
        .modal .sponsor-list .spon .link-conditions{
            text-decoration: none;
            font-weight: bold;
            color:#638ef8;
        }

        .modal .sponsor-list{
            overflow-y: scroll;
            overflow-x: hidden;
            height: 55vh;
        }
        
        .modal .sponsor-list .spon__content{
            
        }
        .modal .sponsor-list .spon__logo img{
            width: 200px;
            height: auto;
        } 
        .modal .sponsor-list .spon .checkbox {
            opacity: 0;
            position: absolute;
        }
        .modal .sponsor-list .spon .checkbox:checked + .label .ball {
            transform: translateX(23px);
        }
        .modal .sponsor-list .spon .checkbox:checked + .label  {
            background-color: #2196f3;
        }
        
        .modal .sponsor-list .spon .label {
            background-color: #ccc;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 50px;
            position: relative;
            padding: 5px;
            height: 26px;
            width: 50px;
        }
        .modal .sponsor-list .spon .ball {
            background-color: #fff;
            border-radius: 50%;
            position: absolute;
            top: 2px;
            left: 2px;
            height: 22px;
            width: 22px;
            transition: transform 0.3s linear;
        }
    `
    document.head.appendChild(styles);
}


function getSponsors(apiKey, appId, formKey, container) {
    let auth = getAuthenticationHeaders();
    var miInit = {
        method: 'GET',
        headers: {
            ...auth,
            "content-type": "application/json",
        },
        mode: 'cors',
        credentials: 'include',
        cache: 'reload',
    };

    fetch(lgConfig.isDev === true ? `http://${API_URL_DEV}/capture/getSponsorList` : `https://${API_URL_PROD}/capture/getSponsorList`, miInit)
        .then(function (response) {

            response.json().then(function (dataMain) {

                for (let i = 0; i < dataMain.length; i++) {
                    let sponsor = dataMain[i];
                    
                    let sponsor_item = /*html*/`
                    <div id="${sponsor.sponsorId}" class="row spon spon_${sponsor.sponsorId}">
                        <div class="spon__checkbox col-sm-2">
                            <div class="form-group form-check">
                                <input type="checkbox" value="${sponsor.sponsorId}"  class="spon-checkbox form-check-input" id="checkbox-${sponsor.sponsorId}" checked>
                            </div>
                        </div>
                        <div class="spon__content col-sm-5">
                        </div>
                        <div class="spon__logo col-sm-5">
                        </div>
                    </div>
                    `
                    let dom_sponsor_item = stringToHTML(sponsor_item);
                    let spon_content = dom_sponsor_item.querySelector('.spon__content');

                    if(sponsor.name){
                        let sponsor_name = document.createElement('p');
                        sponsor_name.classList.add('spon_title','m-0','font-weight-bold');
                        sponsor_name.innerText = sponsor.name;
                        spon_content.appendChild(sponsor_name);
                    }
                        
                    if(sponsor.address){
                        let sponsor_address = document.createElement('p');
                        sponsor_address.classList.add('spon_address','m-0');
                        sponsor_address.innerText = sponsor.address;
                        spon_content.appendChild(sponsor_address);
                    }
                    
                    if(sponsor.description){
                        let sponsor_description = document.createElement('p');
                        sponsor_description.classList.add('spon_description','m-0');
                        sponsor_description.innerText = sponsor.description;
                        spon_content.appendChild(sponsor_description);

                    }

                    if(sponsor.link_conditions){
                        let sponsor_link_conditions = document.createElement('p');
                        sponsor_link_conditions.classList.add('spon_link_conditions','m-0');
                        let link = document.createElement('a');
                        link.setAttribute('target','_blank');
                        link.setAttribute('href',sponsor.link_conditions);
                        link.innerText = "Condiciones Legales";
                        link.classList.add('link-conditions');
                        sponsor_link_conditions.appendChild(link);
                        spon_content.appendChild(sponsor_link_conditions);
                    }

                    let spon_logo = dom_sponsor_item.querySelector('.spon__logo');

                    if(sponsor.logo){
                        let img_logo = document.createElement('img');
                        img_logo.src = sponsor.logo;
                        spon_logo.appendChild(img_logo);
                    }
                    container.appendChild(dom_sponsor_item);
                }

            });


        }).catch(function (error) {

        });
}

/***
 * BirthDay Selector
 */


function fetchBirthDayObject() {
    var Days = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];// index => month [0-11]
    var Month = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];


    if (document.querySelector("[data-type='birth']")) {
        fetchBirtDayMonth();
        fetchBirtDayDays(31);
        fetchBirtDayYear();
    }

}
function fetchBirtDayYear() {
    let toYear = parseInt((new Date().getFullYear())) - 17;
    let fromYear = toYear - 80;

    var birthDaySelect = document.querySelector("[data-type='birthday-year']");
    birthDaySelect.innerHTML = '';
    createSelectPlaceholder(birthDaySelect);
    for (let year = toYear; year > fromYear; year--) {
        let MonthOpt = document.createElement("option");
        MonthOpt.value = year;
        MonthOpt.text = year
        birthDaySelect.add(MonthOpt, null);
    }

}
function fetchBirtDayMonth() {
    var Month = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    var Days = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];// index => month [0-11]
    if (typeof (document.querySelector("[data-type='birthday-month']")) !== "undefined") {
        var birthDaySelect = document.querySelector("[data-type='birthday-month']");
        birthDaySelect.innerHTML = '';
        createSelectPlaceholder(birthDaySelect);
        birthDaySelect.onchange = function (changeMonth) {
            let selMonth = changeMonth.target.value

            if (!isNaN(parseInt(selMonth)))
                fetchBirtDayDays(Days[selMonth]);
        };
        for (let month = 0; month < Month.length; month++) {
            let MonthOpt = document.createElement("option");
            MonthOpt.value = month;
            MonthOpt.text = Month[month];
            birthDaySelect.add(MonthOpt, null);
        }
    }
}

function fetchBirtDayDays(nDays) {
    if (typeof (document.querySelector("[data-type='birthday-month']")) !== "undefined") {
        var birthDaySelect = document.querySelector("[data-type='birthday-day']");
        let default_value = birthDaySelect.value;
        birthDaySelect.innerHTML = "";
        createSelectPlaceholder(birthDaySelect);
        for (let day = 1; day < nDays + 1; day++) {
            let DayOpt = document.createElement("option");
            DayOpt.value = day;
            DayOpt.text = day;
            birthDaySelect.add(DayOpt, null);
        }
        if(default_value && valueExist(default_value,birthDaySelect))
            birthDaySelect.value = default_value;
    }
}
function isLeapYear(year) {
    year = parseInt(year);
    if (year % 4 != 0) {
        return false;
    } else if (year % 400 == 0) {
        return true;
    } else if (year % 100 == 0) {
        return false;
    } else {
        return true;
    }
}

function checkValidateForm(formOrigin){

  return Array.prototype.filter.call(document.getElementsByTagName("form")[0], function (form) {
        
        if (form.type === "checkbox" && form.hasAttribute('required')) {

            if (form.checked === false) {
                form.parentNode.classList.add('bg-warning');
                form.focus();
                return true;
            } else {
                form.classList.remove('is-invalid');
            }

        } else if (form.type === "number" && form.getAttribute('pattern')) {
            var text = new RegExp(form.getAttribute('pattern'));

            if (!text.test(form.value)) {
                form.classList.add('is-invalid'); form.focus();
            } else {
                form.classList.remove('is-invalid');
            }
            return true;
        }
        if(form.tagName == 'SELECT' && form.value == ""){
            form.classList.add('is-invalid');
        }
        if (form.checkValidity() === false) {
            form.classList.add('is-invalid');
            form.focus();
            return true;
        }else{
            form.classList.remove('is-invalid');
            return false;
        }

    }, false);
}

function createPlaceholderSelectQuestion(){
    let sls = document.querySelectorAll('[data-slg-type="C-SELECT-OPTIONS"]');
    for (let i = 0; i < sls.length; i++) {
        const select = sls[i];
        createSelectPlaceholder(select);
    }
}

let currentStep=getParameterByName("stepId");

document.addEventListener("DOMContentLoaded", function(event) {

    /*** fetchBirtday Select **/
    fetchBirthDayObject();

    /* Create placeholder componentes SELECT-QUESTION */
    createPlaceholderSelectQuestion();
    /** AutoPopulate Query to Form **/
    let populateKey = Object.keys(getPopulateQuery())
    if (populateKey.length)
        Object.entries(populateKey).forEach(([key, value]) => {
            if (typeof (document.getElementsByName(value)) !== "undefined" && typeof (document.getElementsByName(value)[0]) !== "undefined")
                document.getElementsByName(value)[0].value = getParameterByName(value);
        });

    if (typeof (lgConfig) !== "undefined") {
        if (typeof (lgConfig.form) === "undefined" && document.getElementsByTagName("form").length > 0) {
            let formOrigin = document.getElementsByTagName("form")[0];

            if(!formOrigin) {  removeOverlay();  return;}
            let button_submit = formOrigin.querySelector("button[type=submit]");

            if(!button_submit) { removeOverlay(); return;}
            let tag_link = button_submit.querySelector('a[href]');
            if (tag_link) {
                tag_link.removeAttribute('href');
            }
            formOrigin.setAttribute("novalidate", "novalidate");
            formOrigin.addEventListener('submit', function (event) {

                createOverlay();

                let validation = checkValidateForm(formOrigin);

                event.preventDefault(); // Mientras estamos enviando el lead no nos deja hacer otro envio


                if (SubmitLead) {
                    SubmitLead = false;
                    if (validation.length > 0) {
                        removeOverlay();
                        SubmitLead = true;
                        return false;
                    }
                    if (document.querySelectorAll(".is-invalid").length === 0) {

                        let FormData = getArrayFromForm(formOrigin);
                        if (parseInt(currentStep) > 0) {

                            sendOptions(formOrigin, lgConfig.apiKey, lgConfig.appId);
                        } else {
                            sendData(FormData, lgConfig.apiKey, lgConfig.appId);
                        }
                      //  removeOverlay();
                    }


                } else {
                    removeOverlay();
                    return false;
                }
                return false;
            });
        }


        /* if (document.querySelector("#sponsor")) {
            let modal = document.querySelector('#sponsor');
            let modal_content = modal.querySelector('.modal-content');
            let container = modal.querySelector('.modal-content .modal-body .sponsor-list');
            let btn_close = modal.querySelector(`[data-bs-dismiss='modal']`);
            let trigger = document.querySelector(`[data-target='#sponsor']`);


            btn_close.addEventListener('click', (e) => {
                modal.classList.remove('show');
            });
            modal.addEventListener('click', function (e) {
                if (modal_content.contains(e.target)) {
                    // Clicked in box
                } else {
                    // Clicked outside the box
                    modal.classList.remove('show');
                }
            });

            trigger.addEventListener('click', (e) => {
                modal.classList.toggle('show');
            });

            container.innerHTML = "";

            
        } */
        /**
         * Init modal sponsor
         */
        let modal_sponsor = document.querySelector('#sponsor');
        if (modal_sponsor) {
            addStylesSwitchSponsors();
            initModalSponsors();
            let sponsor_list = modal_sponsor.querySelector('.modal-content .modal-body .sponsor-list');
            sponsor_list.innerHTML = "";
            getSponsors(lgConfig.apiKey, lgConfig.appId, lgConfig.formKey, sponsor_list);

        }
        /**
         * REmove Loader
         */

        removeOverlay();

    }

    /**
     * Init config questions
     */
    initQuestionsOptions();

});

function getFieldsFollowInQuery(){

    /* Only support simple fields */
    let fields = document.querySelectorAll('[ data-slg-follow-in-query ]');
    let response = {};
    for (let i = 0; i < fields.length; i++) {
        const value = fields[i].value;
        const name = fields[i].name;
        if(name && value)
            response = {
                ...response,
                [name]:value
            }
    }

    return response;
}

/**
 * next Step
 */
function nextStep(stepId,leadId){
    // let query = "?step" + stepId + ".html?leadId=" + leadId + "&stepId=" + stepId;
    let urlNextStep = "step" + stepId + ".html";
    let query = "";
    let currentDataQuery = getPopulateQuery();
    let filedsFollowInQuery = getFieldsFollowInQuery();

    let queryData = {
        ...currentDataQuery,
        ...filedsFollowInQuery,
        stepId,
        leadId
    };

    for (const key in queryData) {
        if (Object.hasOwnProperty.call(queryData, key)) {
            const element = queryData[key];
            query += key + "=" + element + "&";
            
        }
    }

    query = query.slice(0,-1);

    window.setTimeout(function () {
        location.href = urlNextStep + "?" + query;
    },600);
}
function getFormQuestion(form){
    var unindexed_array = $(form).serializeArray();
    var indexed_array = [];
    $.map(unindexed_array, function(n, i){ // $ eliminar Debera ser usado con JS Vainilla
        /***
         * Evaluar si es un coreg.
         */

        let questionMatch = n['name'].match(/\[(.*)]/);

        if(questionMatch){

            let QuestionId = questionMatch[1];
            if(n['value'] && n['value'] != ''){
                if(document.getElementsByName(n['name'])[0] &&
                    document.getElementsByName(n['name'])[0].getAttribute("data-slg-coregistros") &&
                    JSON.parse(document.getElementsByName(n['name'])[0].getAttribute("data-slg-coregistros")).length > 0)
                {
                    let textValue=document
                            .querySelector("[data-slg-question-id='" +  QuestionId + "']")
                            .querySelector("[data-slg-type='C-CONTAINER-COREGISTRO'] input")
                            .value;
                    indexed_array.push({
                        "question_id": QuestionId,
                        "option_id":  n['value'],
                        "text": textValue
                    });
                        
                }
                else {
                    indexed_array.push({
                        "question_id": QuestionId,
                        "option_id":  n['value'],
                    });
                }

            }

        }

       // indexed_array[n['name']] = n['value'];
    });

    return indexed_array;
}
function prepareQuestions(FormData){
        return {
            "answers":getFormQuestion(FormData),
            "step_id": getParameterByName("stepId"),
            "leadId": getParameterByName("leadId")
        };

}
/**
 * Enviar las questions
 */
function sendOptions(FormData) {

    let auth = getAuthenticationHeaders();
    var miInit = {
        method: 'POST',
        headers: {
            ...auth,
            "content-type": "application/json",
        },
        mode: 'cors',
        credentials: 'include',
        cache: 'reload',
        body: JSON.stringify(prepareQuestions(FormData))
    };

        fetch(lgConfig.isDev === true ? `http://${API_URL_DEV}/capture/response` : `https://${API_URL_PROD}/capture/response`, miInit)
        .then(function (response) {

            /** Solo cuando tenemos Steps Habilitados **/

            if(typeof (stepsConfig) !== "undefined"){
                nextStep(parseInt(parseInt(stepsConfig.currentStep)+1),getParameterByName("leadId"));
            }

        });


}



